import { useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { filterContext } from "../ConciliacionPage";
import * as conciliacion from '../../../services/conciliacion.service';
import { useNavigate } from "react-router-dom";
import { TableFooter } from "@mui/material";

const columns = [
  { id: "as400", label: "AS400", minWidth: 1 },
  { id: "articulo", label: "Árticulo", minWidth: 1 },
  { id: "descripcion", label: "Descripción", minWidth: 1 },
  { id: "invinicial", label: "Inicial Transito", minWidth: 1},
  { id: "impresiones", label: "Impresiones Confirmadas", minWidth: 11},
  { id: "devoluciones", label: "Devoluciones en turno", minWidth: 1 },
  { id: "total_impresiones", label: "Etiquetas Totales", minWidth: 1 },
  { id: "total_impresiones_piezas", label: "Piezas Totales", minWidth: 1 },
  { id: "transito", label: "Etiqueta", minWidth: 1 },
  { id: "transito_piezas", label: "Piezas", minWidth: 1 },
  { id: "recepciones", label: "Etiqueta", minWidth: 1 },
  { id: "recepciones_piezas", label: "Piezas", minWidth: 1 }
];

const Conciliacion = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState()
  const navigate = useNavigate();
  const { filtros } = useContext(filterContext);
  const [conciliaciones, setConciliaciones] = useState([]);
  const [totales, setTotales] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const calculateTotal = (array, field) => array.reduce((accumulator, currentValue) => accumulator + currentValue[field] , 0);

  useEffect(() => {
    if(!filtros) {navigate(`/v1/conciliacion`)} else {
      setConciliaciones([])
      setIsFetching(true);
      conciliacion.getConciliacion(filtros.area, filtros.fecha, filtros.turno)
      .then(resp => {
        setConciliaciones(resp)
        setTotales([
          calculateTotal(resp, 'invinicial'),
          calculateTotal(resp, 'impresiones'),
          calculateTotal(resp, 'devoluciones'),
          calculateTotal(resp, 'total_impresiones'),
          calculateTotal(resp, 'total_impresiones_piezas'),
          calculateTotal(resp, 'transito'),
          calculateTotal(resp, 'transito_piezas'),
          calculateTotal(resp, 'recepciones'),
          calculateTotal(resp, 'recepciones_piezas')
        ])
      })
      .finally(() => setIsFetching(false))
    }
  }, [filtros])

  const handleSelectResult = (pro) => {
    setId(pro.item_id)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow >
                <TableCell align="center" colSpan={8} sx={{fontSize: '12px', backgroundColor: '#f1f1f1'}}>
                  PRODUCCIÓN
                </TableCell>
                <TableCell align="center" colSpan={2} sx={{fontSize: '12px'}}>
                  TRANSITO COMPARTIDO
                </TableCell>
                <TableCell align="center" colSpan={2} sx={{fontSize: '12px', backgroundColor: '#f1f1f1'}}>
                  RECEPCIONES DE TRAFICO
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontSize: '12px' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell 
                    style={{ color: '#1976d2'}}
                  >
                    TOTALES
                  </TableCell>

                  {totales.map((t, idx) => (
                    <TableCell key={idx} style={{ color: '#1976d2' }}>{t}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {conciliaciones.length > 0 ? conciliaciones
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow 
                          hover 
                          role="checkbox"
                          selected={row.item_id === id}
                          tabIndex={-1} 
                          key={row.item_id}
                          sx={{cursor: 'pointer'}}
                          onClick={() => handleSelectResult(row)}
                      >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell key={column.id} align={column.align} sx={{fontSize: '11px'}}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }): undefined}
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={conciliaciones.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={isFetching}
      >
        <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
        <Typography variant="h4" gutterBottom>
          Cargando Conciliación
        </Typography>
      </Backdrop>
    </>
  );
};

export default Conciliacion;
