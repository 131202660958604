//import { URL_LABELS } from "./environments";
import AppRouter from "./routers/AppRouter";

const App = ()=> {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
