import { URL_WEBSOCKET } from "../environments";
import socketIOClient from "socket.io-client";
import { createContext, useContext, useEffect, useState } from "react";

const SocketContext = createContext(null);

export const SocketProvider = ({children}) => {

    const printer = window.localStorage.getItem("printer") || "";
    
    useEffect(() => {

        return (() => {
            socket.disconnect();
        })
    }, [])

    const socket = socketIOClient(URL_WEBSOCKET, { query: { type: 'client', printer }, perMessageDeflate: false });
    

    return (
        <SocketContext.Provider value={{socket}}>
            {children}
        </SocketContext.Provider>
    )
}

export const useSocket = () => {
    return useContext(SocketContext)
}
