import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Fab from '@mui/material/Fab';
import CalculateIcon from "@mui/icons-material/Calculate";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useNavigate } from "react-router-dom";
import Calculadora from "./components/Calculadora";
import { useEffect, useState } from "react";
import ReimpresionesModal from "./components/ReimpresionesModal";
import { useProduct } from "../../hooks/useProduct";
import { lpnService } from "../../services/lpn.service";
import { useSocket } from "../../hooks/useSocket";

const OrdenesPanel = ({ orden, handleFetchReprints, area, user }) => {
  const { socket } = useSocket()
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [zpl, setZpl] = useState("");
  const [openReprint, setOpenReprint] = useState(false)
  const { product, setProduct, counter, setCounter, setArticleReturned } = useProduct();

  useEffect(() => {
    console.log('RERENDER ORDENESPANEL')
  }, [])

  const handleReturnToAreas = () => {
    navigate(-1)
  }

  /* const handlerCreateLpn = (quantity) => {
    handleCreateLpn(quantity)
  } */

  const handleReprints = () => {
    setOpenReprint(true)
  }
  const handlerCreateLpn = (cantidad) => {
    lpnService({
      batchId: product.batchId,
      itemId: product.itemId,
      area,
      equipoId: product.equipoId || 0,
      maqId: product.maquinaId || 0,
      lote: product.lote,
      cantidad: cantidad,
      userId: user.userId,
    })
      .then((resp) => {
        if(resp?.status.toLowerCase() === 's') {
          if (product.batchId === resp?.orden?.batchId) {
            const ot = resp.orden;
            setZpl(resp?.zpl)
            setArticleReturned(ot)
            setCounter(counter + 1)
            
            socket.emit('beforeSendPrint', {ot, area});
            socket.emit("printzpl", {zpl: resp?.zpl, print: resp?.print, lpn: resp?.lpn, area});
          } else {
            const ot = {...resp?.orden, batchIdPrev: product.batchId};
            setArticleReturned(ot)
            setCounter(counter + 1)
            setProduct(ot)

            socket.emit('beforeSendPrint', {ot, area});
            socket.emit("printzpl", {zpl: resp?.zpl, print: resp?.print, lpn: resp?.lpn, area});
          }
        } else if(resp?.status.toLowerCase() === 'w') {
          const ot = {batchId: product.batchId, cantidad: cantidad, consumo: cantidad};
          setArticleReturned(ot)
          setProduct(null)
          
          socket.emit('beforeSendPrint', {ot, area});
          socket.emit("printzpl", {zpl: resp?.zpl, print: resp?.print, lpn: resp?.lpn, area});
          //throw new Error(resp.message)
        } else {
          throw new Error(resp.message)
        }
      })
      .catch(error => {
        alert(error)
      })
      .finally(() => console.log("aqui va el mensaje final"));
  };

  return (
    <>
      <Card sx={{ minWidth: 275 }} className="ordenesPanel">
        <CardContent>
          {product && <div className="panel">
            <img 
              src={`https://rga.alpura.com/label/dev/products/${product.oracle}.svg`}
              height="250px"
              alt="producto"
              onError={({currentTarget}) => {
                currentTarget.src="/images/notfound.png"
              }}
            />
            {product?.estandar >= 0 && <Button
              variant="contained"
              onClick={() => handlerCreateLpn(product.estandar)}
              fullWidth
            >
              Estandar
            </Button>}

            {product?.tarima >= 0 && <Button
              variant="contained"
              onClick={() => handlerCreateLpn(product.tarima)}
              fullWidth
            >
              Tarima
            </Button>}

            {product?.pila >= 0 && <Button
              variant="contained"
              onClick={() => handlerCreateLpn(product.pila)}
              fullWidth
            >
              Pila
            </Button>}

            {product?.canastilla >= 0 && <Button
              variant="contained"
              onClick={() => handlerCreateLpn(product.canastilla)}
              fullWidth
            >
              Canastilla
            </Button>}
            {product && <Button
              variant="contained"
              onClick={() => handleReprints()}
              fullWidth
            >
              Reimpresiones
            </Button>}
          </div>}

          {!product && <div className="nothing">
              <img src="/images/no_data.png" width="250px" alt="producto" />
          </div>
          }

          <div className="actions">
            {product && <Fab 
              color="primary" 
              aria-label="add"
              onClick={() => setIsOpen(true) }
            >
              <CalculateIcon />
            </Fab>}
            <Fab 
              color="primary" 
              aria-label="add"
              onClick={() => handleReturnToAreas()}
            >
              <SwapHorizIcon />
            </Fab>
          </div>
          <Calculadora 
            isOpen={isOpen} 
            setIsOpen={setIsOpen} 
            product={product}
            handlerCreateLpn={handlerCreateLpn}
          />
        </CardContent>
      </Card>
      {openReprint && <ReimpresionesModal 
        openReprint={openReprint} 
        setOpenReprint={setOpenReprint}
        area={area}
        itemId={product?.itemId}
        batchId={product?.batchId}
      />}
    </>
  );
};

export default OrdenesPanel;
