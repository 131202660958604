import { useEffect, useState } from "react";
import Fab from "@mui/material/Fab";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import Alert from "@mui/material/Alert";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth } from "../../hooks/useAuth";
import areasService from "../../services/areas.service";
import { Form, Formik } from "formik";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as turnos from "../../services/turnos.service";
import { turnosService } from "../../services/turnos.service";
import TextField from '@mui/material/TextField';
import { iniciarSesion } from "../../services/auth.service";

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

const fabRedStyle = {
  color: "common.white",
  bgcolor: "#ad1457",
  "&:hover": {
    bgcolor: "#811457",
  },
};

function CorteTurno() {
  const [openCorte, setOpenCorte] = useState(false);
  const { user } = useAuth();
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("");
  const [validatingPassword, setValidatingPassword] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [validateMessage, setValidateMessage] = useState("")
  const [showPassword, setShowPassword] = useState(true)

  const [isCutting, setIsCutting] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [turno, setTurno] = useState("");

  useEffect(() => {
    areasService(user.username).then((areas) => setAreas(areas));
  }, []);

  const handleClose = () => {
    setTurno("");
    setOpenCorte(false);
    setValidatingPassword(false);
    setValidatePassword(false);
    setValidateMessage("")
    setShowPassword(true)
  };

  const handleClickOpen = () => {
    setError("");
    setStatus("");
    setMessage("")
    setOpenCorte(true);
    setValidatingPassword(false);
    setValidatePassword(false);
    setValidateMessage("")
    setShowPassword(true)
  };

  const handleCorte = () => {
    if (area) {
      setIsCutting(true);
      setTimeout(() => {
        turnos
          .corte(user.userId, area)
          .then((resp) => {
            if (resp.status !== "s") setError(resp.mensage);
            else {
                setStatus(resp?.status)
                setMessage(resp?.mensage.toUpperCase())
            }
          })
          .catch(() => setError("Se genero un error al cierre, intente mas tarde"))
          .finally(() => setIsCutting(false));
      }, 3000);
    }
  };

  return (
    <>
      <Fab
        color="inherit"
        aria-label="cut"
        sx={{ ...fabStyle, ...fabRedStyle }}
        onClick={handleClickOpen}
      >
        <ContentCutIcon />
      </Fab>
      <Dialog
        open={openCorte}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Desea realizar el Corte de turno?"}
        </DialogTitle>
        <DialogContent>
          {error && <Alert severity="error" sx={{marginBottom: '0.5rem'}}>{error}</Alert>}
          {status === 's' ? (<Alert severity="success" sx={{marginBottom: '0.5rem'}}>{message}</Alert>) :
          (<Formik
            initialValues={{ area: "" }}
            validate={({ area }) => {
              let errors = {};
              if (!area) errors.area = "Campo obligatorio";
              else setArea(area);
              return errors;
            }}
          >
            {({ values, errors, touched, setFieldValue, handleSubmit, handleChange }) => (
              <Form>
                <FormControl
                  error={!!errors.area}
                  fullWidth
                  sx={{ marginTop: "1rem" }}
                >
                  <InputLabel htmlFor="area">
                    Seleccione Área para el Corte
                  </InputLabel>
                  <Select
                    id="area"
                    name="area"
                    label="Seleccione Área para el Corte"
                    placeholder="Seleccione Área para el Corte"
                    value={values.area}
                    disabled={isCutting}
                    onChange={e => {
                        setError("")
                        setShowPassword(true)
                        setValidatePassword(false);
                        setValidateMessage("")
                        turnosService(e.target.value).then((t) => setTurno(t.turno.toString()));
                        setFieldValue('area', e.target.value)
                    }}
                  >
                    {areas.map((o) => (
                      <MenuItem key={o.id} value={o.area}>
                        {o.area}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!turno && <TextField
                    disabled
                    style={{marginTop: '2rem'}}
                    label="Turno a cerrar"
                    defaultValue={(turno === "1" ? "1er" : turno === "2" ? "2do" : "3er") + ' Turno'}
                  />}
                  <FormHelperText>
                    {errors.area ? errors.area : undefined}
                  </FormHelperText>
                </FormControl>
              </Form>
            )}
          </Formik>)}

          {(!!turno && showPassword) && <Formik
            initialValues={{ password: "" }}
            validate={({ password }) => {
              let errors = {};
              setValidateMessage("");
              setValidatePassword(false)
              if (!password) errors.password = "Campo obligatorio";
              return errors;
            }}
            onSubmit={ async ({ password }) => {
              setValidatingPassword(true)
              iniciarSesion({password, username: user.username}).then((u) => {
                setValidatePassword(true);
                setValidateMessage("")
                setShowPassword(false)
              }).catch(({response}) => {
                console.log(response.data.message)
                setValidateMessage("Contraseña incorrecta")
              }).finally(() => setValidatingPassword(false))
            }}
          >
            {({ values, errors, touched, setFieldValue, handleSubmit, handleChange }) => (
              <Form>
                <FormControl
                  error={!!errors.password}
                  fullWidth
                  sx={{ marginTop: "1rem" }}
                >
                  
                  <TextField
                    style={{marginTop: '2rem'}}
                    label="Password"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    type="password"
                  />
                  <FormHelperText>
                    {validatingPassword ? 
                      "Validando datos" :
                      validateMessage ? validateMessage : undefined}
                  </FormHelperText>
                </FormControl>
              </Form>
            )}
          </Formik>}
        </DialogContent>
        { status !== 's' && (<DialogActions>
          <Button onClick={handleClose} disabled={isCutting}>
            Cancelar
          </Button>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              onClick={handleCorte}
              variant="contained"
              autoFocus
              disabled={isCutting || !!error || !validatePassword}
            >
              Corte de Turno
            </Button>
            {isCutting && (
              <CircularProgress
                size={24}
                sx={{
                  color: "primary",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </DialogActions>)}
      </Dialog>
    </>
  );
}

export default CorteTurno;
