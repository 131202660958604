import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";


import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import BookOnlineTwoToneIcon from "@mui/icons-material/BookOnlineTwoTone";
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import AutoAwesomeMosaicTwoToneIcon from '@mui/icons-material/AutoAwesomeMosaicTwoTone';
import JoinLeftTwoToneIcon from '@mui/icons-material/JoinLeftTwoTone';
import PrecisionManufacturingTwoToneIcon from '@mui/icons-material/PrecisionManufacturingTwoTone';
import WorkspacesTwoToneIcon from '@mui/icons-material/WorkspacesTwoTone';

import "./Navbar.css";
import Profile from "./Profile";

const Navbar = ({ children }) => {
  const navigate = useNavigate();
  const [user] = useLocalStorage("user");
  if (!user) navigate("/");

  return (
    <>
      <nav className="navbar">
        <div className="brand">
          <img src="/images/alpura.png" alt="alpura" />
        </div>
        <ul className="navlink">
          {user.menus.includes("etiquetado") && <li>
            <NavLink to="etiquetado">
              <ReceiptLongTwoToneIcon />
              Etiquetado
            </NavLink>
          </li>}
          {user.menus.includes("conciliacion") && <li>
            <NavLink to="conciliacion">
              <FactCheckTwoToneIcon />
              Conciliación
            </NavLink>
          </li>}
          {user.menus.includes("acomodo") && <li>
            <NavLink to="acomodo">
              <PrecisionManufacturingTwoToneIcon />
              Acomodo
            </NavLink>
          </li>}
          {user.menus.includes("picking") && <li>
            <NavLink to="picking">
              <WorkspacesTwoToneIcon />
              Picking
            </NavLink>
          </li>}
        </ul>
        <div className="profile">
          <Profile />
        </div>
      </nav>
      <div className="child">{children}</div>
    </>
  );
};

export default Navbar;
