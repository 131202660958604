import { interceptor } from './interceptor';

const lpnService = (data) => {
  const url = `lpn`;
  const interceptorAxios = interceptor();
  return interceptorAxios
    .post(url, data)
    .then((res) => res.data)
    .catch(error => {
      throw new Error(error.response.data.message);
    })
};

const reprintService = (lpnId) => {
  const url = `reprint`;
  const interceptorAxios = interceptor();
  return interceptorAxios
    .post(url, { lpnId })
    .catch(() => {
      throw new Error("Error no controlado");
    })
    .then((res) => res.data);
};

const receptionService = (data) => {
    const url = `lpn/recepcionar`;
    const interceptorAxios = interceptor();
    return interceptorAxios
      .post(url, data)
      .catch(() => {
        throw new Error("Error no controlado");
      })
      .then((res) => res.data);
  };

export { lpnService, reprintService, receptionService };
