import { useEffect, useState } from "react"
import { URL_IMAGES } from "../../../environments"
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { Typography } from "@mui/material";

const Image = ({product}) => {
  const [existImg, setExistImg] = useState(false)

  return (
    <>
        {/* existImg && <img 
        id="currentPhoto" 
        src={`${URL_IMAGES}${product.oracle}.svg`} 
        onError={ () => {
            setExistImg(false)
        }}
        alt="" 
        width="100" 
        height="120" /> */}
        {!existImg && (
            <Typography variant="h8">
                <div style={{display: 'flex', gap: '1rem', flexDirection: 'column', alignItems: 'center', marginTop: '2rem'}}>
                    <img src="/images/notfound.png" alt="404" style={{width: '50%', height: '50%'}} />
                    <Typography variant="h5" color="gray">
                        Producto sin imagen
                    </Typography>
                    <Typography variant="h8">
                        {product.item}
                    </Typography>
                </div>
            </Typography>
        )}
    </>
  )
}

export default Image