import { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import areasService from "../../services/areas.service";
import { turnosService } from "../../services/turnos.service";
import ConciliacionFiltros from "./ConciliacionFiltros";
import "./ConciliacionPage.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Fab from "@mui/material/Fab";
import ContentCutIcon from "@mui/icons-material/ContentCut";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth } from "../../hooks/useAuth";
import CorteTurno from "./CorteTurno";

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

const fabRedStyle = {
  color: "common.white",
  bgcolor: "#ad1457",
  "&:hover": {
    bgcolor: "#811457",
  },
};

export const filterContext = createContext();

const ConciliacionPage = () => {
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("");
  const [turno, setTurno] = useState("0");
  const [fecha, setFecha] = useState([]);
  const { user } = useAuth()

  const navigate = useNavigate();

  const [filtros, setFiltros] = useState(null);

  useEffect(() => {
    console.log('Page')
    areasService(user.username.toUpperCase()).then((areas) => {
      setAreas(areas)
    });
  }, []);

  const fetchTurnos = (area) => {
    setArea(area);
    turnosService(area).then((t) => setTurno(t.turno.toString()));
  };

  const handlerFilters = (f) => {
    setFiltros({ ...f, fecha: format(f.fecha, "dd-MM-yyyy") });
    navigate(f.reporte);
  };

  return (
    <>
      <ConciliacionFiltros handlerFilters={handlerFilters} areas={areas} />
      <filterContext.Provider value={{ area, turno, fecha, areas, filtros }}>
        <Outlet />
      </filterContext.Provider>
      {user.cierre && <CorteTurno />}
    </>
  );
};

export default ConciliacionPage;
