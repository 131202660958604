import axios from 'axios'
import { URL_LABELS } from "../environments"

export const interceptor = () => {
    const interceptorAxios = axios.create({baseURL: URL_LABELS});
    const user = (JSON.parse(window.localStorage.getItem("user")));
    const token = user ? user.token : ""
    
    if(token)
        interceptorAxios.interceptors.request.use(
            (request) => {
                request.headers["x-auth-token"] = token;
                return request;
            },
            (err) => err
        );
    return interceptorAxios
}