import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useFetchArea } from "./components/useFetchArea";
import './EtiquetadoPage.css'
import Avatar from "@mui/material/Avatar";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TableViewTwoToneIcon from '@mui/icons-material/TableViewTwoTone';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { grey } from "@mui/material/colors";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { useSocket } from "../../hooks/useSocket";
import CachedIcon from '@mui/icons-material/Cached';

const EtiquetadoPage = () => {
  const { areas, isFetching } = useFetchArea();
  const { socket } = useSocket()
  const navigate= useNavigate();
  const { user,  isAuthenticated} = useAuth();
  const [open, setOpen] = useState(false);
  const [printer, setPrinter] = useState(
    window.localStorage.getItem("printer") ?
    window.localStorage.getItem("printer") :
    ""
  )
  const [printerTmp, setPrinterTmp] = useState(
    window.localStorage.getItem("printer") ?
    window.localStorage.getItem("printer") :
    ""
  )
  
  useEffect(() => {
    socket.on("statusPrinter", sts => localStorage.setItem("available", sts))
  }, [])

  const handleDetail = (area) => {
    navigate(area);
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handlePrinter = e => {
    e.preventDefault();
    window.localStorage.setItem('printer', printerTmp);
    console.log()
    if(printerTmp) socket.emit("join", printerTmp)
    setPrinter(printerTmp)
    setOpen(false);
  }

  const handlerReload = () => {
    window.location.reload();
  }

  if (isFetching) return <p>...loading</p>;

  return (
    <>
      <Collapse in={!printer}>
        <Alert 
          severity="warning"
          action={
            <Button color="warning" onClick={() => setOpen(true)}>
              <LocalPrintshopOutlinedIcon />
              <p style={{fontSize: '10px', marginLeft: '0.5rem'}}>Configurar</p>
            </Button>
          }
          >
            La impresora no se encuentra configurada
        </Alert>
      </Collapse>
      <Collapse in={!!printer}>
        <Alert 
          severity="info"
          action={
            <Button color="info" onClick={() => setOpen(true)}>
              <LocalPrintshopOutlinedIcon />
              <p style={{fontSize: '10px', marginLeft: '0.5rem'}}>Configurar</p>
            </Button>
          }
          >
            Impresora <b>{printer}</b> configurada
        </Alert>
      </Collapse>
      <div className="reload">
        <Button variant="outlined" onClick={() => handlerReload()} startIcon={<CachedIcon />}>
          Actualizar
        </Button>
      </div>
      <div className="areas">
        {/* <li key={area.id} onClick={() => handleDetail(area.area)}>
            {area.area} - <span>{area.total}</span>
          </li> */}
        {areas.map(area => (
          <Card key={area.id} sx={{ width: "17%", cursor: 'pointer' }} onClick={() => handleDetail(area.area)}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: area.total > 0 ? "#1976d2" : grey[500]}}
                aria-label="recipe"
              >
                {area.total}
              </Avatar>
            }
            title={area.area}
            subheader="Área"
          />
        </Card>
        ))}
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <div className="headerDialogPrinter">
            <LocalPrintshopOutlinedIcon /><span>Configurar impresora</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handlePrinter} style={{padding: '1rem 0'}}>
            <TextField 
              id="printer-setting"
              label="Nombre de la impresora"
              variant="outlined"
              value={printerTmp}
              onChange={e => setPrinterTmp(e.target.value)}
              fullWidth
            />
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EtiquetadoPage;
