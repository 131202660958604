import React, { useContext, useState } from "react";
import { filterContext } from "../ConciliacionPage";
import * as turnos from "../../../services/turnos.service";

function Corte() {
  const { area, turno, areas } = useContext(filterContext);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleCorte = (pUserId, pArea) => {
    turnos
      .corte(pUserId, pArea)
      .then((resp) => setMessage(resp))
      .finally(() => setError);
  };
  return (
    <div className="corte">
      <h1>Corte de Turno</h1>
      <select placeholder="Seleccione una Área" value={area} disabled>
        <option></option>
        {areas.map((area) => (
          <option key={area.id} value={area.area}>
            {area.area}
          </option>
        ))}
      </select>

      <select placeholder="Seleccione un Turno" value={turno} disabled>
        <option></option>
        <option value="1">Primer Turno</option>
        <option value="2">Segundo Turno</option>
        <option value="3">Tercer Turno</option>
      </select>

      <button onClick={() => handleCorte(248212, area)}>
        REALIZAR CORTE DE TURNO
      </button>

      {message && <div className="success">{message}</div>}
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default Corte;
