import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import AcomodoPage from "../pages/acomodo/AcomodoPage";
import ConciliacionPage from "../pages/conciliacion/ConciliacionPage";
import Conciliacion from "../pages/conciliacion/components/Conciliacion";
import Impresiones from "../pages/conciliacion/components/Impresiones";
import Transito from "../pages/conciliacion/components/Transito";
import EtiquetadoPage from "../pages/etiquetado/EtiquetadoPage";
import OrdenesPage from "../pages/etiquetado/OrdenesPage";
import NoFoundPage from "../pages/NoFoundPage";
import PickingPage from "../pages/picking/PickingPage";
/* import "./AppRouter.css"; */
import Recepcion from "../pages/conciliacion/components/Recepcion";
import Devoluciones from "../pages/conciliacion/components/Devoluciones";
import Reimpresiones from "../pages/conciliacion/components/Reimpresiones";
import Corte from "../pages/conciliacion/components/Corte";
import LoginPage from "../pages/login/LoginPage";
import { AuthProvider } from "../hooks/useAuth";
import Version from "./Version";
import { useEffect, useState } from "react";
import * as turnos from '../services/turnos.service';
import { Guard } from "./Guard";

const AppRouter = () => {
  /* const [version, setVersion] = useState(0);

  useEffect(() => {
    turnos.turnosService('YOGHURT')
      .then( t => setVersion(t.turno) )
  }, [])
 */

  return (
    <AuthProvider>
      <Router>
        
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            {/* <Route path={`/v${version}`} element={<Version />} > */}
            <Route path={`/v1`} element={<Version />} >
              <Route path="etiquetado" element={<Guard><EtiquetadoPage /></Guard>} />
              <Route path="etiquetado/:area" element={<Guard><OrdenesPage /></Guard>} />
              <Route path="conciliacion" element={<Guard><ConciliacionPage /></Guard>}>
                <Route path="conciliacion" element={<Guard><Conciliacion /></Guard>} />
                <Route path="impresiones" element={<Guard><Impresiones /></Guard>} />
                <Route path="transito" element={<Guard><Transito /></Guard>} />
                <Route path="recepcion" element={<Guard><Recepcion /></Guard>} />
                <Route path="devoluciones" element={<Guard><Devoluciones /></Guard>} />
                <Route path="reimpresiones" element={<Guard><Reimpresiones /></Guard>} />
                <Route path="corte" element={<Guard><Corte /></Guard>} />
              </Route>
              <Route path="acomodo" element={<Guard><AcomodoPage /></Guard>} />
              <Route path="picking" element={<Guard><PickingPage /></Guard>} />
            </Route>  
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="*" element={<NoFoundPage />} />
          </Routes>
      
      </Router>
    </AuthProvider>
  );
};

export default AppRouter;
