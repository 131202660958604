import { forwardRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { lpnService, reprintService } from "../../services/lpn.service";
import reprintsService from "../../services/reprints.service";
import { useFetchOrden } from "./components/useFetchOrden";
import "./EtiquetadoPage.css";
import OrdenesPanel from "./OrdenesPanel";
import OrdenesTable from "./OrdenesTable";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import SkeletonTable from "./components/SkeletonTable";
import ErrorModal from "./components/ErrorModal";
import { ProductProvider } from "../../hooks/useProduct";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert2 = forwardRef(function Alert2(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const OrdenesPage = () => {
  const [lpn, setLpn] = useState(null);
  const [productActive, setProductActive] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [zpl,setZpl] = useState("");
  const [numPrint,setNumPrint] = useState("");
  const [reprints, setReprints] = useState([]);
  const [isFetchingReprint, setIsFetchingReprint] = useState(false);
  const [product, setProduct] = useState(null);
  const { user } = useAuth();
  const [openError, setOpenError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("")
  
  const { area } = useParams();
  const [printer, setPrinter] = useState(
    window.localStorage.getItem("printer") ?
    window.localStorage.getItem("printer") :
    ""
  )
  //const { ordenes, isFetching } = useFetchOrden(area);

  //if (isFetching) return <p>...loading</p>;

  
  
  useEffect(() => { 
    console.log('ORDENESPAGE.JS')
  }, [])

  const handleSelectProduct = (product) => {
    setReprints([]);
    setProductActive(product);
  };

  

  const handleFetchReprints = () => {
    setIsFetchingReprint(true);
    setReprints([]);
    reprintsService({ area, itemId: productActive.itemId, batchId: productActive.batchId })
      .then((resp) => {
        setReprints(resp);
      })
      .finally(() => setIsFetchingReprint(false));
  };

  const handleReprint = (lpnId) => {
    reprintService(lpnId)
      .then(() => console.log('Regresamo de imprimir el lpnId: ' + lpnId))
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage("")
    setOpenAlert(false);
  };

  /* if (isCreating) return <p>...Generando LPN</p>; */

  return (
    <ProductProvider>
      
        <Collapse in={printer === ""}>
          <Alert severity="warning">No se encontro una impresora configurada, favor de registrar impresora</Alert>
        </Collapse>
        

        <div className="ordenes">
          <OrdenesTable area={area} />
          <OrdenesPanel area={area} user={user}/>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
            open={isCreating}
          >
            <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
            <Typography variant="h6" gutterBottom>
              GENERANDO ETIQUETA ...{product?.item}
            </Typography>
          </Backdrop>
          <ErrorModal openError={openError} setOpenError={setOpenError} message={message} />
        </div>
        <Snackbar open={openAlert} autoHideDuration={1000} onClose={handleCloseAlert}>
          <Alert2 onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
            {message}
          </Alert2>
        </Snackbar>
    </ProductProvider>
  );
};

export default OrdenesPage;
