import { useEffect, useReducer, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useFetchOrden } from "./components/useFetchOrden";
import Button from "@mui/material/Button";
import { useProduct } from "../../hooks/useProduct";
import ordenesService from "../../services/ordenes.service";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { useSocket } from "../../hooks/useSocket";
import Dialog from '@mui/material/Dialog';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parse } from "date-fns";
import { EnhancedTableHead } from "./components/EnhancedTableHead";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const columns = [
  { id: "equipo", label: "Equipo", minWidth: 1 },
  { id: "as400", label: "AS400", minWidth: 1 },
  { id: "oracle", label: "Articulo", minWidth: 1 },
  { id: "item", label: "Descripción", minWidth: 1 },
  { id: "lote", label: "Lote", minWidth: 1 },
  { id: "udm", label: "UDM", minWidth: 1 },
  { id: "impresiones", label: "Impresiones", minWidth: 1 },
  { id: "resto", label: "Restantes", minWidth: 1 },
  { id: "estandar", label: "Cantidad", minWidth: 1 },
];

const types = {
  update: 'UPDATE',
  load: 'LOAD',
  updateLote: 'UPDATELOTE',
  remove: 'REMOVE',
  replace: 'REPLACE'
}

const initialArticles = []

const reducer = (state, action) => {
  switch(action.type) {
    case types.load:
      return action.payload.map( article => ({...article, loteBase: article.lote}));
    case types.update: {
      const edit = action?.payload;
      if (edit) {
        const statePrev = state.map( art => 
          art.batchId === edit.batchId
          ?
          {...art, consumo: edit.consumo, resto: edit.resto}
          :
          art)
        return statePrev.map( art => 
        art.batchId === edit.batchId && art.equipoId === edit.equipoId
        ?
        {...art, consumo: edit.consumo, impresiones: edit.impresiones}
        :
        art )
        } else {
          return  state
        }
    }
    case types.updateLote: {
      const edit = action?.payload;
      return edit
      ? 
        state.map( art =>
          art.batchId === edit.batchId
          ?
          {...art, lote: edit.lote}
          :
            art
        )
      : 
        state
    }
    case types.remove: {
      const remove = action?.payload;
      return remove ?
        state.filter( art => art.batchId !== remove.batchId)
      :
        state
    }
    case types.replace: {
      const replace = action?.payload;
      return replace ?
        state.map( art => art.batchId === replace.batchIdPrev ?
            {...replace, batchIdPrev: null, loteBase: art.lote}
          :
            art)
        :
        state
    }
    default: 
      return state;
  }
}

const OrdenesTable = ({ area }) => {
  const { socket } = useSocket()
  const [articles, dispatch]  = useReducer(reducer, initialArticles)
  

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('equipo');
  const [selected, setSelected] = useState([]);

  const [ordenes, setOrdenes] = useState([])
  const { counter, setProduct, articleReturned, product } = useProduct();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusPrinter, setStatusPrinter] = useState(
    window.localStorage.getItem("available") ?
    window.localStorage.getItem("available") :
    ""
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("")
  const [openUpdateLote, setOpenUpdateLote] = useState(false)
  const [date, setDate] = useState(new Date());

  const printer = 
    window.localStorage.getItem("printer") ?
    window.localStorage.getItem("printer") :
    ""

  const [productId, setProductId] = useState(
      window.localStorage.getItem('productId')
      ? 
      JSON.parse(window.localStorage.getItem('productId'))
      :
      null
  );


  useEffect(() => {
    socket.on("beforeSendPrintTo", resp => {
      const ot = resp.ot;
      if(ot && resp.area === area) {
        if (ot.batchIdPrev) {
          setProductId(ot.id)
          localStorage.setItem('productId', JSON.stringify(ot.id))
          setProduct({...ot, batchIdPrev: null, loteBase: ot.lote})
          dispatch({
            type: types.replace,
            payload: ot
          })
        } else {
          dispatch({
            type: ot.cantidad === ot.consumo
                  ?
                  types.remove
                  :
                  types.update
                  ,
            payload: ot
          })
        }
      }
    })

    socket.on("statusPrinter", sts => setStatusPrinter(sts))

    socket.on("finished", resp => {
      if(resp.area === area) {
        setMessage(resp.message)
        setOpenAlert(true)
        setTimeout(() => setOpenAlert(false), 700)
      }
      
    })
  }, [])

  useEffect(() => {
    socket.on("disconnect", () => {
      setStatusPrinter("No tenemos conexión al servidor")
    })
  }, [])

  useEffect(() => {
    ordenesService(area)
      .then(ordenes => {
        dispatch({
          type: types.load,
          payload: ordenes
        })
      })
  }, []);
  
  const handleSelectProduct = (productT) => {
      setProductId(productT.id)
      localStorage.setItem('productId', JSON.stringify(productT.id))
      setProduct(productT)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenUpdateLote = (e, lote) => {
    e.stopPropagation()
    setDate(parse(lote, 'dd-MM-yyyy', new Date()))
    setOpenUpdateLote(true)
  }

  const handleChangeLote = (newDate) => {
    setOpenUpdateLote(false)
    const dateFormat = format(newDate, 'dd-MM-yyyy')
    setProduct({...product, lote:  dateFormat})
    dispatch({
      type: types.updateLote,
      payload: {
        batchId: product.batchId,
        lote: dateFormat
      }
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = columns.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <div className="ordenesTable">
      <Collapse in={!!statusPrinter && !!printer}>
        <Alert severity="error">{statusPrinter}</Alert>
      </Collapse>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "1rem" }}>
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={columns.length}
              columns={columns}
            />
            {/* <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontSize: "12px" }}
                    sortDirection='asc'
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead> */}
            <TableBody>
              {articles.length > 0
                ? stableSort(articles, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          selected={productId === row.id}
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleSelectProduct(row)}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{ fontSize: "12px" }}
                              >
                                {column.id === 'lote' ? 
                                  (
                                    <Button color={row.loteBase === row.lote ? 'primary' : 'error'} onDoubleClick={(e) => handleOpenUpdateLote(e, value)}>{value}</Button>
                                  )
                                :
                                  column.id === 'resto' | column.id === 'estandar' ? 
                                    <Typography style={{fontWeight: 'bold'}} variant="body3" color={((row.resto / row.cantidad) * 100) < 15 ? '#B71C1C': undefined}>
                                      { value < 0 ? 0 : value }
                                    </Typography>  
                                  :
                                    <Typography style={{fontWeight: 'bold'}} variant="body2">
                                    {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                    </Typography>
                                }
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                : undefined}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={articles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={false}
      >
        <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
        <Typography variant="h4" gutterBottom>
          Cargando la ordenes
        </Typography>
      </Backdrop>
      <Collapse in={!!openAlert} sx={{marginTop: '1rem'}}>
        <Alert severity="success">{message}</Alert>
      </Collapse>
      <Dialog onClose={() => setOpenUpdateLote(false)} open={openUpdateLote}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CalendarPicker date={date} onChange={(newDate) => handleChangeLote(newDate)} />
      </LocalizationProvider>
      </Dialog>
    </div>
  );
};

export default OrdenesTable;
