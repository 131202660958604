import { useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { filterContext } from "../ConciliacionPage";
import * as conciliacion from '../../../services/conciliacion.service';
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "as400", label: "AS400", minWidth: 1 },
  { id: "articulo", label: "Árticulo", minWidth: 1 },
  { id: "descripcion", label: "Descripción", minWidth: 1 },
  { id: "unidad_total", label: "UNID", minWidth: 1},
  { id: "pzstotal", label: "PZS", minWidth: 11},
  { id: "unidad_estandar", label: "UNID", minWidth: 1 },
  { id: "pzsestandar", label: "PZS", minWidth: 1 },
  { id: "unida_pila", label: "UNID", minWidth: 1 },
  { id: "pzspila", label: "PZS", minWidth: 1 },
  { id: "unidad_tarima", label: "UNID", minWidth: 1 },
  { id: "pzstarima", label: "PZS", minWidth: 1 },
  { id: "unidad_restos", label: "UNID", minWidth: 1 },
  { id: "pzsrestos", label: "PZS", minWidth: 1 },
];

const Impresiones = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [itemId, setItemId] = useState()
  const navigate = useNavigate();

  const { filtros } = useContext(filterContext);
  const [impresiones, setImpresiones] = useState([]);
  const [isFetching, setIsFetching] = useState(false)
  const [totales, setTotales] = useState([])

  const calculateTotal = (array, field) => array.reduce((accumulator, currentValue) => accumulator + currentValue[field] , 0);

  useEffect(() => {if(!filtros) {navigate(`/v1/conciliacion`)} else {
      setImpresiones([])
      setIsFetching(true)
      conciliacion.getImpresiones(filtros?.area, filtros?.fecha, filtros?.turno)
      .then(resp => {
        setImpresiones(resp)
        setTotales([
          calculateTotal(resp, 'unidad_total'),
          calculateTotal(resp, 'pzstotal'),
          calculateTotal(resp, 'unidad_estandar'),
          calculateTotal(resp, 'pzsestandar'),
          calculateTotal(resp, 'unida_pila'),
          calculateTotal(resp, 'pzspila'),
          calculateTotal(resp, 'unidad_tarima'),
          calculateTotal(resp, 'pzstarima'),
          calculateTotal(resp, 'unidad_restos'),
          calculateTotal(resp, 'pzsrestos')
        ])
      })
      .finally(() => setIsFetching(false))
    }
  }, [filtros])
  
  const handleSelectResult = (pro) => {
    setItemId(pro.item_id)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '1rem' }}>
        <TableContainer sx={{ maxHeight: '70vh' }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow >
                <TableCell align="center" colSpan={3} sx={{fontSize: '12px', backgroundColor: '#f1f1f1'}}>
                  ÁRTICULO
                </TableCell>
                <TableCell align="center" colSpan={2} sx={{fontSize: '12px'}}>
                  TOTAL
                </TableCell>
                <TableCell align="center" colSpan={2} sx={{fontSize: '12px', backgroundColor: '#f1f1f1'}}>
                  ESTANDAR
                </TableCell>
                <TableCell align="center" colSpan={2} sx={{fontSize: '12px'}}>
                  PILA
                </TableCell>
                <TableCell align="center" colSpan={2} sx={{fontSize: '12px', backgroundColor: '#f1f1f1'}}>
                  TARIMA
                </TableCell>
                <TableCell align="center" colSpan={2} sx={{fontSize: '12px'}}>
                  RESTOS
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontSize: '12px' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell 
                    style={{ color: '#1976d2'}}
                  >
                    TOTALES
                  </TableCell>

                  {totales.map((t, idx) => (
                    <TableCell key={idx} style={{ color: '#1976d2' }}>{t}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {impresiones.length > 0 ? impresiones
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow 
                          hover 
                          role="checkbox"
                          selected={row.item_id === itemId}
                          tabIndex={-1} 
                          key={row.item_id}
                          sx={{cursor: 'pointer'}}
                          onClick={() => handleSelectResult(row)}
                      >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell key={column.id} align={column.align} sx={{fontSize: '11px'}}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }): undefined}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={impresiones.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={isFetching}
      >
        <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
        <Typography variant="h4" gutterBottom>
          Cargando las Impresiones
        </Typography>
      </Backdrop>
    </>
  )
}

export default Impresiones