import axios from "axios"
import { URL_LABELS_PICKING } from "../environments"

const getPicking = (orgId) => {
    const url = `${URL_LABELS_PICKING}picking?orgId=${orgId}`;
    
    return axios
    .get(url)
    .catch( () => {throw new Error('Error no controlado')})
    .then(res => res.data)
}

const printPicking = (cantidad, itemId, orgId, userId) => {
    const url = `${URL_LABELS_PICKING}picking`;

    return axios
    .post(url, { userId, orgId, itemId, cantidad })
    .catch( () => {throw new Error('Error no controlado')})
    .then(res => res.data)
}

export { getPicking, printPicking };