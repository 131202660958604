import { useEffect, useState } from "react"
import { useAuth } from "../../../hooks/useAuth";
import areasService from "../../../services/areas.service";

export const useFetchArea = () => {
    const [areas, setAreas] = useState([]);
    const { user } = useAuth()
    const [isFetching, seIsFetching] = useState(true);

    useEffect(() => {
        areasService(user.username.toUpperCase())
            .then(areas => setAreas(areas))
            .finally(() => seIsFetching(false))
    }, []);

    return { areas, isFetching }
}
