import axios from "axios"
import { URL_LABELS_PICKING } from "../environments"

const getOrgs = () => {
    const url = `${URL_LABELS_PICKING}organizations`;

    return axios
    .get(url)
    .catch( () => {throw new Error('Error no controlado')})
    .then(res => res.data)
}

export default getOrgs;