import { interceptor } from './interceptor';
//25-10-2022

const getConciliacion = (area, fecha, turno) => {
    const url = `conciliacion?area=${area}&fecha=${fecha}&turno=${turno}&reporte=REP_GENERAL`;
    const interceptorAxios = interceptor();
    return interceptorAxios.get(url).catch( error => {throw new Error('Error no controlado')}).then(res => res.data)
}

const getImpresiones = (area, fecha, turno) => {
    const url = `conciliacion/unidades?area=${area}&fecha=${fecha}&turno=${turno}&reporte=REP_IMPRESIONES`;
    const interceptorAxios = interceptor();
    return interceptorAxios.get(url).catch( error => {throw new Error('Error no controlado')}).then(res => res.data)
}

const getTransito = (area, fecha, turno) => {
    const url = `conciliacion/transito?area=${area}&fecha=${fecha}&turno=${turno}&reporte=REP_TRANSITO`;
    const interceptorAxios = interceptor();
    return interceptorAxios.get(url).catch( error => {throw new Error('Error no controlado')}).then(res => res.data)
}

const getRecepcion = (area, fecha, turno) => {
    const url = `conciliacion/unidades?area=${area}&fecha=${fecha}&turno=${turno}&reporte=REP_RECEPCIONES`;
    const interceptorAxios = interceptor();
    return interceptorAxios.get(url).catch( error => {throw new Error('Error no controlado')}).then(res => res.data)
}

const getDevoluciones = (area, fecha, turno) => {
    const url = `conciliacion/transito?area=${area}&fecha=${fecha}&turno=${turno}&reporte=REP_DEVOLUCIONES`;
    const interceptorAxios = interceptor();
    return interceptorAxios.get(url).catch( error => {throw new Error('Error no controlado')}).then(res => res.data)
}

const getReimpresiones = (area, itemId, batchId, turnoImpresion, diaImpresion) => {
    const url = `reprint?area=${area}
        ${itemId ? '&itemId=' + itemId : ''}
        ${batchId ? '&batchId=' + batchId : ''}
        ${turnoImpresion ? '&turnoImpresion=' + turnoImpresion : ''}
        ${diaImpresion ? '&diaImpresion=' + diaImpresion : ''}
    `;
    const interceptorAxios = interceptor();
    return interceptorAxios.get(url).catch( error => {throw new Error('Error no controlado')}).then(res => res.data)
}

export { getConciliacion, getImpresiones, getTransito, getRecepcion, getDevoluciones, getReimpresiones }