import axios from "axios"
import { URL_LABELS } from "../environments"

const iniciarSesion = (credentials) => {
    const url = `${URL_LABELS.replace('v1','public')}auth/login`;
    console.log('1')
    return axios
    .post(url, credentials)
    .then(res => res.data)
}

export { iniciarSesion }