import { useState, forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { iniciarSesion } from "../../services/auth.service";
import * as turnos from "../../services/turnos.service";
import "./LoginPage.css";
import "@fontsource/roboto/500.css";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const { login } = useAuth();
  const [ul, setUl] = useLocalStorage("user");
  const navigate = useNavigate();
  const [version, setVersion] = useState(0);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    localStorage.removeItem("user");
    localStorage.removeItem("productId");
    localStorage.removeItem("available");
  }, []);

  return (
    <>
      <div className="footer"></div>
      <div className="content-app">
        <div className="App">
          <div className="img-login">
            <img src="/images/La-Pura-Crema-Pastor.jpg" alt="etiquetado" />
          </div>
          <div className="title">
            <Typography variant="h5">Iniciar Sesión</Typography>
          </div>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validate={(valores) => {
              let errores = {};
              setErrorLogin(null);
              setOpen(false);

              if (!valores.username) {
                errores.username = "Por favor ingrese su Número de nómina";
              }

              if (!valores.password) {
                errores.password = "Por favor ingrese su contraseña";
              } else if (valores.password.length < 6) {
                errores.password =
                  "La contraseña no debe ser menor a 6 caracteres";
              }
              return errores;
            }}
            onSubmit={async (valores, { resetForm }) => {
              setLoading(true);

              iniciarSesion(valores).then((u) => {
                login(u);
                setUl(u);
                u.menus.includes("etiquetado")
                ?
                navigate(`/v1/etiquetado`)
                :
                  u.menus.includes("conciliacion")
                  ?
                  navigate(`/v1/conciliacion`)
                  :
                    u.menus.includes("acomodo")
                    ?
                    navigate(`/v1/acomodo`)
                    :
                    navigate(`/v1/picking`)
              })
              .catch(({response}) => {
                setErrorLogin(response.data.message);
                setOpen(true)
            })
            .finally(() => setLoading(false));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl
                  error={touched.username && errors.username}
                  className="username"
                >
                  <InputLabel htmlFor="username">Número de nómina</InputLabel>
                  <OutlinedInput
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Número de nómina"
                    autoComplete="off"
                    label="Número de nómina"
                    variant="outlined"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText>
                    {touched.username && errors.username
                      ? errors.username
                      : undefined}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  error={touched.password && !!errors.password}
                  className="password"
                >
                  <InputLabel htmlFor="password">Contraseña</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          color={
                            touched.password && errors.password
                              ? "error"
                              : undefined
                          }
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Contraseña"
                  />
                  <FormHelperText>
                    {touched.password && errors.password
                      ? errors.password
                      : undefined}
                  </FormHelperText>
                </FormControl>
                <Box sx={{ m: 1, position: "relative" }} className="signin">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    size="large"
                    fullWidth
                  >
                    Ingresar
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={32}
                      sx={{
                        color: "#1976d2",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-16px",
                        marginLeft: "-16px",
                      }}
                    />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ position: "absolute", bottom: "16px" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {errorLogin}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default LoginPage;
