import { interceptor } from './interceptor';

const areasService = (username) => {    
    const url = `areas?username=${username}`;
    const interceptorAxios = interceptor();
    
    return interceptorAxios
    .get(url)
    .catch( error => {throw new Error('Error no controlado')})
    .then(res => res.data)
}


export default areasService;