import React, { useContext, useEffect, useReducer, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from '@mui/material/Button';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import * as conciliacion from "../../../services/conciliacion.service";
import { reprintService } from "../../../services/lpn.service";
import { filterContext } from "../ConciliacionPage";
import { useSocket } from "../../../hooks/useSocket";
import { format, parse } from "date-fns";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "area", label: "Área", minWidth: 1 },
  { id: "lote", label: "Lote", minWidth: 1 },
  { id: "lpn", label: "Lpn", minWidth: 1 },
  { id: "oracle", label: "Oracle", minWidth: 1, align: "center" },
  { id: "item", label: "Item", minWidth: 11},
  { id: "udm", label: "UDM", minWidth: 1, align: "center" },
  { id: "cantitdad", label: "Cantidad", minWidth: 1, align: "center" },
  { id: "reimpresiones", label: "Reimpresiones", minWidth: 1, align: "center" },
  { id: "action", label: "Acciones", minWidth: 1, align: "center" }
];

const types = {
  load: 'LOAD',
  update: 'UPDATE'
}

const initalState = []

const reducer = (state, action) => {
  switch(action.type) {
    case types.load:
      return action.payload
    case types.update: {
      const edit = action.payload;
      return edit
      ? 
      state.map( label =>
        label.lpnId === edit.lpnId ? edit : label  
      )
      :
      state
    }
    default: 
      return state;
  }
}

const Reimpresiones = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { filtros } = useContext(filterContext);
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(false)
  const [isReprinting, setIsReprinting] = useState(false)
  const { socket } = useSocket()
  const [reimpresiones, dispatch] = useReducer(reducer, initalState);
  const [labelReturned, setLabelReturned] = useState(null)

  useEffect(() => {
    socket.on("updateReprintList", resp => {
      const fecha = format(parse(filtros.fecha, 'dd-MM-yyyy', new Date()), 'dd/MM/yyyy')
      const mix = `${filtros.area}${filtros.turno}${fecha}`
      if(resp.area === mix) {
        dispatch({
          type: types.update,
          payload: resp.payload
        })
      }
    })
  }, [filtros])

  useEffect(() => {
    if(!filtros) {navigate(`/v1/conciliacion`)} else {
      setIsFetching(true);
      const fecha = format(parse(filtros.fecha, 'dd-MM-yyyy', new Date()), 'dd/MM/yyyy')
      conciliacion
        .getReimpresiones(filtros.area, null, null, filtros.turno, fecha)
        .then((resp) => {
          dispatch({
            type: types.load,
            payload: resp
          })
        })
        .finally(() => setIsFetching(false));
    }
  }, [filtros]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleReprint = (row) => {
    setIsReprinting(true)
    reprintService(row.lpnId)
      .then(resp => {
        if(resp?.status === 's') {
          //setLabelReturned(resp?.reprint)
          const fecha = format(parse(filtros.fecha, 'dd-MM-yyyy', new Date()), 'dd/MM/yyyy')
          /* dispatch({
            type: types.update,
            payload: resp?.reprint
          }) */
          //socket.emit("printzpl", {zpl: resp?.zpl, print: resp?.print});
          socket.emit("reprintzpl", {zpl: resp?.zpl, print: 1, lpn: row.lpn, area: `${filtros.area}${filtros.turno}${fecha}`, payload: resp?.reprint});
        } else {
          throw new Error(resp.mensaje)
        }
      })
    setTimeout(() => setIsReprinting(false), 1500);
  }
  
  return (
    <div className="reimpresiones">
      <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '1rem' }}>
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontSize: '12px' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reimpresiones.length > 0 ? reimpresiones
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow 
                        hover 
                        role="checkbox"
                        tabIndex={-1} 
                        key={row.lpnId}
                        sx={{cursor: 'pointer'}}
                    >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if(column.id === 'action') return (
                        <TableCell key={column.id} align={column.align} sx={{fontSize: '11px'}}>
                          <Button 
                            variant="outlined" 
                            startIcon={<LocalPrintshopIcon />}
                            onClick={() => handleReprint(row)}
                          >
                            Reimprimir
                        </Button>
                        </TableCell>
                      )
                      return (
                        <TableCell key={column.id} align={column.align} sx={{fontSize: '11px'}}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }): undefined}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={reimpresiones.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={isFetching}
        >
        <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
        <Typography variant="h4" gutterBottom>
          Cargando lista de reimpresiones
        </Typography>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isReprinting}
      >
        <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
        <Typography variant="h6" gutterBottom>
          REIMPRIMIENDO ETIQUETA
        </Typography>
      </Backdrop>
    </div>
  );
};

export default Reimpresiones;
