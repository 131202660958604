import { interceptor } from './interceptor';

const turnosService = (area) => {
    const url = `turno?area=${area}`;
    const interceptorAxios = interceptor();
    
    return interceptorAxios
    .get(url)
    .catch( error => {throw new Error('Error no controlado')})
    .then(res => res.data)
}

const corte = (userId, area) => {
    const url = `turno`;
    const interceptorAxios = interceptor();
    
    return interceptorAxios.post(url, {userId, area}).catch( error => {throw new Error('Error no controlado')}).then(res => res.data)
}


export { turnosService, corte }