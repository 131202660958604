import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { pink } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
const ErrorModal = ({openError, setOpenError, message}) => {
    const handleClose = () => {
        setOpenError(false)
    }
  return (
    <Dialog onClose={handleClose} open={openError}>
      <DialogTitle>
        <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <Avatar sx={{ bgcolor: pink[500] }}>
                <CancelIcon />
            </Avatar>
            Error al generar Etiqueta
        </div>
      </DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
    </Dialog>
  )
}

export default ErrorModal