import { forwardRef, useState } from "react";
import useFetchOrgs from "./hooks/useFetchOrgs";
import * as picking from "../../services/picking.service";
import "./PickingPage.css";
import PickingProducts from "./PickingProducts";
import LocalPrintshopTwoToneIcon from "@mui/icons-material/LocalPrintshopTwoTone";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Fab from "@mui/material/Fab";

import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../hooks/useAuth";

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSocket } from "../../hooks/useSocket";
import { Button } from "@mui/material";
import Image from "./components/Image";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@mui/material";
import TextField from '@mui/material/TextField';

const Alert2 = forwardRef(function Alert2(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PickingPage = () => {
  const [org, setOrg] = useState("");
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [printer, setPrinter] = useState(
    window.localStorage.getItem("printer") ?
    window.localStorage.getItem("printer") :
    ""
  )
  const [printerTmp, setPrinterTmp] = useState(
    window.localStorage.getItem("printer") ?
    window.localStorage.getItem("printer") :
    ""
  )
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const { user } = useAuth()
  const { orgs, isFetching } = useFetchOrgs();
  const { socket } = useSocket()

  const getProducts = (orgId) => {
    setOrg(orgId);
    setIsFetchingProducts(true);
    setProducts([]);
    setProduct(null)
    picking
      .getPicking(orgId)
      .then((ps) => setProducts(ps))
      .finally(() => setIsFetchingProducts(false));
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handlePrinter = e => {
    e.preventDefault();
    window.localStorage.setItem('printer', printerTmp);
    console.log()
    if(printerTmp) socket.emit("join", printerTmp)
    setPrinter(printerTmp)
    setOpen(false);
  }

  const handlePrint = () => {
    setIsPrinting(true);
    picking
      .printPicking(-1, product.itemId, org, user.userId)
      .then((resp) => {
        if(resp?.status === 's') {
          socket.emit("printzpl", {zpl: resp?.zpl, print: resp?.print, lpn: product.oracle, area: 'picking'});
        } else {
          throw new Error(resp.message)
        }
      })
      .finally(() => setIsPrinting(false));
  };

  return (
    <>
      <Collapse in={!printer}>
        <Alert 
          severity="warning"
          action={
            <Button color="warning" onClick={() => setOpen(true)}>
              <LocalPrintshopOutlinedIcon />
              <p style={{fontSize: '10px', marginLeft: '0.5rem'}}>Configurar</p>
            </Button>
          }
          >
            La impresora no se encuentra configurada
        </Alert>
      </Collapse>
      <Collapse in={!!printer}>
        <Alert 
          severity="info"
          action={
            <Button color="info" onClick={() => setOpen(true)}>
              <LocalPrintshopOutlinedIcon />
              <p style={{fontSize: '10px', marginLeft: '0.5rem'}}>Configurar</p>
            </Button>
          }
          >
            Impresora <b>{printer}</b> configurada
        </Alert>
      </Collapse>
      <div className="picking">
        <div className="orgs">
          <FormControl fullWidth>
            <InputLabel id="orgs-select-label">Seleccione una Organizacion</InputLabel>
            <Select
              labelId="demo-orgs-select-label"
              value={org}
              label="Seleccione una Organizacion"
              onChange={(e) => getProducts(e.target.value)}
            >
              {orgs.map((o) => (
                <MenuItem key={o.orgId} value={o.orgId}>
                  <ListItemIcon sx={{ color: "#1976d2" }}>
                    <Avatar
                      sx={{
                        bgcolor: "#1976d2",
                        color: "#fff",
                        fontSize: 12,
                        marginRight: 1,
                        fontWeight: 600,
                      }}
                    >
                      {o.code}
                    </Avatar>
                  </ListItemIcon>
                  {o.org}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {product && <Button 
            fullWidth 
            variant="contained"
            endIcon={<LocalPrintshopTwoToneIcon />}
            size="large"
            color="warning"
            onClick={() => handlePrint()}
          >
            Imprimir Etiqueta
          </Button>}
          {product && (
            <div className="orgs-img">
              <Image product={product}/>
            </div>
          )}
        </div>

        <PickingProducts products={products} setProduct={setProduct} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={isFetchingProducts}
        >
          <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
          <Typography variant="h4" gutterBottom>
            Cargando lista de productos
          </Typography>
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={isPrinting}
        >
          <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
          <Typography variant="h6" gutterBottom>
            IMPRIMIENDO ...{product?.item}
          </Typography>
        </Backdrop>
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <div className="headerDialogPrinter">
            <LocalPrintshopOutlinedIcon /><span>Configurar impresora</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handlePrinter} style={{padding: '1rem 0'}}>
            <TextField 
              id="printer-setting"
              label="Nombre de la impresora"
              variant="outlined"
              value={printerTmp}
              onChange={e => setPrinterTmp(e.target.value)}
              fullWidth
            />
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PickingPage;
