import { Form, Formik } from "formik";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from '@mui/material/Button';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import areasService from "../../services/areas.service";
import { turnosService } from "../../services/turnos.service";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';

const ConciliacionFiltros = ({ handlerFilters, areas }) => {

  return (
    <div className="filtros">
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Formik
            initialValues={{ area: "", turno: "", fecha: new Date(), reporte: "" }}
            validate={(values) => {
              let errors = {};
              if(!values.area) errors.area = 'Campo obligatorio';
              if(!values.turno) errors.turno = 'Campo obligatorio';
              if(!values.fecha) errors.fecha = 'Campo obligatorio';
              if(!values.reporte) errors.reporte = 'Campo obligatorio';
              return errors;
            }}
            onSubmit={async (valores) => {
                handlerFilters(valores)
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl error={!!errors.area} fullWidth>
                  <InputLabel htmlFor="area">Seleccione Área</InputLabel>
                  <Select id="area" name="area" label="Seleccione un Área" placeholder="Seleccione un Área" value={values.area} 
                    onChange={e => {
                      setFieldValue('area', e.target.value);
                      turnosService(e.target.value).then((t) => setFieldValue('turno', t.turno.toString()));
                    }}
                  >
                    {areas.map((o) => (
                      <MenuItem key={o.id} value={o.area}>
                        {o.area}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.area
                      ? errors.area
                      : undefined}
                  </FormHelperText>
                </FormControl>
                <FormControl error={!!errors.turno} fullWidth>
                  <InputLabel htmlFor="turno">Seleccione Turno</InputLabel>
                  <Select id="turno" name="turno" label="Seleccione un Turno" placeholder="Seleccione un Turno" value={values.turno} onChange={handleChange} onBlur={handleBlur} >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="1">PRIMER TURNO</MenuItem>
                    <MenuItem value="2">SEGUNDO TURNO</MenuItem>
                    <MenuItem value="3">TERCER TURNO</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors.turno
                      ? errors.turno
                      : undefined}
                  </FormHelperText>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Seleccione Fecha"
                    inputFormat="dd-MM-yyyy"
                    value={values.fecha}
                    onBlur={handleBlur}
                    onChange={value =>
                      setFieldValue('fecha', value, true)
                    }
                    renderInput={(params) => 
                      <TextField 
                        id="fecha"
                        name="fecha"
                        error={Boolean(errors.fecha)}
                        helperText={errors.fecha}
                        
                        fullWidth
                        {...params} 
                      />
                    }
                  />
                </LocalizationProvider>
                <FormControl error={!!errors.reporte} fullWidth>
                  <InputLabel htmlFor="reporte">Seleccione Reporte</InputLabel>
                  <Select id="reporte" name="reporte" label="Seleccione un Reporte" placeholder="Seleccione un Reporte" value={values.reporte} onChange={handleChange} onBlur={handleBlur} >
                    <MenuItem value="conciliacion">CONCILIACIÓN</MenuItem>
                    <MenuItem value="impresiones">IMPRESIONES</MenuItem>
                    <MenuItem value="transito">TRÁNSITO</MenuItem>
                    <MenuItem value="recepcion">RECEPCIÓN</MenuItem>
                    <MenuItem value="devoluciones">DEVOLUCIONES</MenuItem>
                    <MenuItem value="reimpresiones">REIMPRESIONES</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors.reporte
                      ? errors.reporte
                      : undefined}
                  </FormHelperText>
                </FormControl>
                <Button type="submit" variant="contained" startIcon={<TroubleshootIcon />} fullWidth>
                    consultar
                </Button>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

export default ConciliacionFiltros;
