import { forwardRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { receptionService } from "../../services/lpn.service";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { red, orange, green, grey } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TagIcon from "@mui/icons-material/Tag";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import DangerousIcon from "@mui/icons-material/Dangerous";
import "./AcomodoPage.css";
import AcomodoHistory from "./AcomodoHistory";
import SearchLpn from "./components/SearchLpn";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export const colors = {
  N: grey[500],
  S: green[500],
  E: red[500],
  W: orange[500],
};

export const icons = {
  N: <QuestionMarkIcon />,
  S: <ThumbUpOffAltIcon />,
  E: <DangerousIcon />,
  W: <PriorityHighIcon />,
};

const severity = {
  S: "success",
  E: "error",
  W: "warning",
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AcomodoPage = () => {
  const [results, setResults] = useState([]);

  const [lpn, setLpn] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("O");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setStatus('O');
  };
  return (
    <div className="acomodo">
      <div className="lpn">
        <div className="panel">
          <SearchLpn
            setLpn={setLpn}
            setMessage={setMessage}
            setStatus={setStatus}
            setResults={setResults}
            results={results}
          />
          <div className="detail">
            <Card sx={{ width: "100%" }}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: lpn ? "#1976d2" : grey[500] }}
                    aria-label="recipe"
                  >
                    {lpn ? <TipsAndUpdatesIcon /> : <QuestionMarkIcon />}
                  </Avatar>
                }
                title={lpn?.item}
                subheader="Descripción"
              />
            </Card>

            <Card sx={{ width: "50%" }}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: lpn ? "#1976d2" : grey[500] }}
                    aria-label="recipe"
                  >
                    {lpn ? <QrCodeIcon /> : <QuestionMarkIcon />}
                  </Avatar>
                }
                title={lpn?.oracle}
                subheader="Articulo"
              />
            </Card>

            <Card sx={{ width: "50%" }}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: lpn ? "#1976d2" : grey[500] }}
                    aria-label="recipe"
                  >
                    {lpn ? <TagIcon /> : <QuestionMarkIcon />}
                  </Avatar>
                }
                title={lpn?.cantidad}
                subheader="Cantidad"
              />
            </Card>

            <Card sx={{ width: "50%" }}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: lpn ? "#1976d2" : grey[500] }}
                    aria-label="recipe"
                  >
                    {lpn ? <CalendarMonthIcon /> : <QuestionMarkIcon />}
                  </Avatar>
                }
                title={lpn?.lote}
                subheader="Lote"
              />
            </Card>
          </div>
        </div>
        {lpn && (
          <div className="product">
            <img
              src="/images/CRAC090.svg"
              alt=""
            />
          </div>
        )}
      </div>
      <AcomodoHistory results={results} />
      {/* {results.length ? (
        <AcomodoHistory results={results} />
      ) : (
        <img src="/images/noFound.png" alt="No Found" />
      )} */}
      <Snackbar
        open={status !== 'O'}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity[status]} sx={{ width: "100%" }}>
          {message ? message : "Nothing"}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AcomodoPage;
