import { interceptor } from './interceptor';

const ordenesService = (area) => {
    const url = `ordenes?area=${area}`;
    const interceptorAxios = interceptor();
    
    return interceptorAxios
    .get(url)
    .catch( error => {throw new Error('Error no controlado')})
    .then(res => res.data)
}


export default ordenesService;