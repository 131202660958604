import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';

import './Calculadora.css'
import { DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';

const Calculadora = ({isOpen, setIsOpen, product, handlerCreateLpn}) => {
    const [quantity, setQuantity] = useState("")
    
    const handleForm = e => {
        e.preventDefault();
        const currentValue = Number(quantity)
        if(currentValue > 0) {
            handlerCreateLpn(currentValue)
            handleClose()
        }
            
    }

    const handleClose = () => {
        setQuantity("")
        setIsOpen(false)
    }

    const handleQuantity = (val) => {
        if(!val.includes(".")) {
            const currentValue = Number(val)
            if(currentValue < product?.estandar)
                setQuantity(currentValue)
        }
    }

    const handleButton = (val) => {
        handleQuantity(quantity + val)
    }
  return (
        <Dialog 
            open={isOpen}
            onClose={() => handleClose()}
        >
            <form onSubmit={handleForm} className="formCalculator">
                <DialogTitle>
                    <input 
                        placeholder='0'
                        value={quantity}
                        onChange={e => handleQuantity(e.target.value)}
                    />
                </DialogTitle>
            
                <DialogContent>
                    <p style={{fontSize: '11px', textAlign: 'center', padding: '0.5rem 0'}}>La cantidad máxima permitida es <b>{product?.estandar - 1}</b></p>
                    <div className='buttons'>
                        <div className='rowbutton'>
                            <Fab onClick={() => handleButton("1")} color="primary" aria-label="1">1</Fab>
                            <Fab onClick={() => handleButton("2")} color="primary" aria-label="1">2</Fab>
                            <Fab onClick={() => handleButton("3")} color="primary" aria-label="1">3</Fab>
                        </div>
                        <div className='rowbutton'>
                            <Fab onClick={() => handleButton("4")} color="primary" aria-label="1">4</Fab>
                            <Fab onClick={() => handleButton("5")} color="primary" aria-label="1">5</Fab>
                            <Fab onClick={() => handleButton("6")} color="primary" aria-label="1">6</Fab>
                        </div>
                        <div className='rowbutton'>
                            <Fab onClick={() => handleButton("7")} color="primary" aria-label="1">7</Fab>
                            <Fab onClick={() => handleButton("8")} color="primary" aria-label="1">8</Fab>
                            <Fab onClick={() => handleButton("9")} color="primary" aria-label="1">9</Fab>
                        </div>
                        <div className='rowbutton'>
                            <Fab onClick={() => handleButton("0")} color="primary" aria-label="1">0</Fab>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" type='submit' fullWidth>IMPRIMIR</Button>
                </DialogActions>
            </form>
        </Dialog>
  )
}

export default Calculadora