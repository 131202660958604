import { Form, Formik } from "formik";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { receptionService } from "../../../services/lpn.service";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const SearchLpn = ({ setLpn, setMessage, setStatus, setResults, results }) => {
  const [isRecived, setIsRecived] = useState(false);
  const [lpnInner, setLpnInner] = useState("");
  const { user } = useAuth();

  return (
    <>
      <Formik
        initialValues={{ lpn: "" }}
        validate={({ lpn }) => {
          let errors = {};
          if (!lpn) errors.lpn = "Favor de ingresar Lpn.";
          else if (lpn.length < 12)
            errors.lpn = "El Lpn no debe ser menor a 12 caracteres";
          else if (lpn.length > 14)
            errors.lpn = "El Lpn no debe ser mayor a 14 caracteres";
          return errors;
        }}
        onSubmit={async (valores, { resetForm }) => {
          if(!isRecived) {
            setIsRecived(true);
            setLpnInner(valores.lpn);

            receptionService({ userId: user.userId, lpn: valores.lpn })
              .then((resp) => {
                let result = {};
                const lpn = resp.lpn;
                const mensaje = resp.mensaje;
                const status = lpn ? mensaje.toLowerCase().includes("ya fue recepcionado") ? "W" : "S" : "E";
                setLpn(lpn);
                setStatus(status);
                setMessage(mensaje);
                if (lpn) {
                  result = { status, lpn: valores.lpn, oracle: lpn.oracle, item: lpn.item, cantidad: lpn.cantidad, udm: lpn.udm, lote: lpn.lote, creationDate: lpn.creationDate, mensaje };
                } else {
                  result = { status, lpn: valores.lpn, oracle: "", item: "", cantidad: "", udm: "", lote: "", creationDate: "", mensaje };
                }
                setResults([result, ...results]);
                resetForm();
              })
              .finally(() => {
                setIsRecived(false);
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormControl error={touched.lpn && errors.lpn} fullWidth>
              <InputLabel htmlFor="lpn">Capture Lpn</InputLabel>
              <OutlinedInput
                type="text"
                id="lpn"
                name="lpn"
                placeholder="Capture Lpn"
                autoComplete="off"
                label="Capture Lpn"
                variant="outlined"
                value={values.lpn}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormHelperText>
                {touched.lpn && errors.lpn ? errors.lpn : undefined}
              </FormHelperText>
            </FormControl>
          </Form>
        )}
      </Formik>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isRecived}
      >
        <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
        <Typography variant="h6" gutterBottom>
          ACOMODANDO LPN {lpnInner}
        </Typography>
      </Backdrop>
    </>
  );
};

export default SearchLpn;
