import { Outlet, Navigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import { useAuth } from "../hooks/useAuth";
import { SocketProvider } from "../hooks/useSocket";

const Version = () => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <Navbar>
      <SocketProvider>
        <Outlet />
      </SocketProvider>
    </Navbar>
  );
};

export default Version;
