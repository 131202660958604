import { createContext, useContext, useState } from "react";

const ProductContext = createContext(null);

export const ProductProvider = ({children}) => {
    const [product, setProduct] = useState(null);
    const [counter, setCounter] = useState(false);
    const [articleReturned, setArticleReturned] = useState(null);

    return (
        <ProductContext.Provider value={{product, setProduct, counter, setCounter, articleReturned, setArticleReturned}}>
            {children}
        </ProductContext.Provider>
    )
}

export const useProduct = () => {
    return useContext(ProductContext);
}