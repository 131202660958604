let URL_LABELS = ''
let URL_WEBSOCKET = ''
let URL_IMAGES = ''
let URL_LABELS_PICKING = ''

switch(process.env.REACT_APP_ENV) {
    case 'production':
        URL_LABELS = 'https://alpura-qa-back.advisoryservicesg.tech/one/api/v1/'
        URL_LABELS_PICKING = 'https://alpura-qa-back.advisoryservicesg.tech/one/api/public/';
        URL_WEBSOCKET = 'https://alpura-one-websocket-5777ud6y2a-uc.a.run.app/'
        URL_IMAGES = 'https://rga.alpura.com/label/dev/products/'
        break;
    case 'qa':
        URL_LABELS = 'https://alpura-qa-back.advisoryservicesg.tech/one/api/v1/';
        URL_LABELS_PICKING = 'https://alpura-qa-back.advisoryservicesg.tech/one/api/public/';
        URL_WEBSOCKET = 'https://alpura-one-websocket-5777ud6y2a-uc.a.run.app/'
        URL_IMAGES = 'https://rga.alpura.com/label/dev/products/'
        break;
    default: 
        URL_LABELS = 'https://alpura-qa-back.advisoryservicesg.tech/one/api/v1/'
        URL_LABELS_PICKING = 'https://alpura-qa-back.advisoryservicesg.tech/one/api/public/';
        URL_WEBSOCKET = 'https://alpura-one-websocket-5777ud6y2a-uc.a.run.app/'
        URL_IMAGES = 'https://rga.alpura.com/label/dev/products/'
        //URL_LABELS = 'http://189.254.4.194:7061/api/v1/';
}
    
export { URL_LABELS, URL_WEBSOCKET, URL_IMAGES, URL_LABELS_PICKING }
