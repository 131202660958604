import { createContext, useContext, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [userLocal] = useLocalStorage('user')
    const [isAuthenticated, setIsAuthenticated] = useState(!!userLocal);
    const [user, setUser] = useState(userLocal);

    
    const login = (u) => {
        setUser(u);
        setIsAuthenticated(true)
    }

    const logout = () => {
        setIsAuthenticated(false)
    }

    return (
        <AuthContext.Provider value={{user, isAuthenticated, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}