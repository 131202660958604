import Dialog from "@mui/material/Dialog";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect, useReducer, useState } from "react";
import * as conciliacion from "../../../services/conciliacion.service";
import { reprintService } from "../../../services/lpn.service";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from '@mui/material/Button';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import './ReimpresionesModal.css'
import TextField from '@mui/material/TextField';
import { useSocket } from "../../../hooks/useSocket";

const columns = [
  { id: "area", label: "Área", minWidth: 1 },
  { id: "lote", label: "Lote", minWidth: 1 },
  { id: "lpn", label: "Lpn", minWidth: 1 },
  { id: "oracle", label: "Oracle", minWidth: 1, align: "center" },
  { id: "item", label: "Item", minWidth: 11},
  { id: "udm", label: "UDM", minWidth: 1, align: "center" },
  { id: "cantitdad", label: "Cantidad", minWidth: 1, align: "center" },
  { id: "reimpresiones", label: "Reimpresiones", minWidth: 1, align: "center" },
  { id: "action", label: "Acciones", minWidth: 1, align: "center" }
];

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const types = {
  load: 'LOAD',
  update: 'UPDATE'
}

const initialReprint = []
const initialReprintBase = []

const reducerReprint = (state, action) => {
  switch(action.type) {
    case types.load:
      return action.payload
    case types.update: {
      const edit = action.payload;
      return edit
      ? 
      state.map( label =>
        label.lpnId === edit.lpnId ? edit : label  
      )
      :
      state
    }
    default:
      return state
  }
}
const reducerReprintBase = (state, action) => {
  switch(action.type) {
    case types.load:
      return action.payload
    case types.update: {
        const edit = action.payload;
        return edit
        ? 
        state.map( label =>
          label.lpnId === edit.lpnId ? edit : label  
        )
        :
        state
      }
    default:
      return state
  }
}

const ReimpresionesModal = ({ openReprint, setOpenReprint, area, itemId, batchId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const [reimpresiones, dispatchReprint] = useReducer(reducerReprint, initialReprint);
  const [reimpresionesBase, dispatchReprintBase] = useReducer(reducerReprintBase, initialReprintBase);
  
  const [isFetching, setIsFetching] = useState(false)
  const [isReprinting, setIsReprinting] = useState(false)
  const [search, setSearch] = useState("")
  const { socket } = useSocket()
  const [labelReturned, setLabelReturned] = useState(null)

  useEffect(() => {
    socket.on("updateReprintList", resp => {
      const mix = `${area}${itemId}${batchId}`
      if(resp.area === mix) {
        dispatchReprint({
          type: types.update,
          payload: resp.payload
        })
        dispatchReprintBase({
          type: types.update,
          payload: resp.payload
        })
      }
    })
  }, [])

  useEffect(() => {
    setIsFetching(true);
    conciliacion
      .getReimpresiones(area, itemId, batchId)
      .then((resp) => {
        dispatchReprint({
          type: types.load,
          payload: resp
        })
        dispatchReprintBase({
          type: types.load,
          payload: resp
        })
      })
      .finally(() => setIsFetching(false));
  }, [batchId]);

  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleReprint = (row) => {
    setIsReprinting(true)
    reprintService(row.lpnId)
      .then( resp => {
        if(resp?.status === 's') {
          setLabelReturned(resp?.reprint)
          socket.emit("reprintzpl", {zpl: resp?.zpl, print: 1, lpn: row.lpn, area: `${area}${itemId}${batchId}`, payload: resp?.reprint});
        } else {
          throw new Error(resp.mensaje)
        }
      }).finally(() => setIsReprinting(false))
  }

  const handleClose = () => {
    setOpenReprint(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
  }

  const searchLpns = (lpn) => {
    setSearch(lpn);
    if(lpn.length > 0) {
      dispatchReprint({
        type: types.load,
        payload: reimpresionesBase
                  .filter(rb => rb.lpn.includes(lpn))
      })
    } else {
      dispatchReprint({
        type: types.load,
        payload: reimpresionesBase
      })
    }
  }

  return (
    <Dialog
        fullScreen
        open={openReprint}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              LISTA DE REIMPRESIONES PARA EL DE ÁREA <b>{area}</b>
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="re-search">
          <form onSubmit={handleSubmit}>
            <TextField 
              id="search-lpn" 
              label="Buscar LPN" 
              variant="outlined"
              value={search}
              onChange={e => searchLpns(e.target.value)}
            />
          </form>
          
        </div>
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '1rem' }}>
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontSize: '12px' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reimpresiones.length > 0 ? reimpresiones
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow 
                        hover 
                        role="checkbox"
                        tabIndex={-1} 
                        key={row.lpnId}
                        sx={{cursor: 'pointer'}}
                    >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if(column.id === 'action') return (
                        <TableCell key={column.id} align={column.align} sx={{fontSize: '11px'}}>
                          <Button 
                            variant="outlined" 
                            startIcon={<LocalPrintshopIcon />}
                            onClick={() => handleReprint(row)}
                          >
                            Reimprimir
                        </Button>
                        </TableCell>
                      )
                      return (
                        <TableCell key={column.id} align={column.align} sx={{fontSize: '11px'}}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }): undefined}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={reimpresiones.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={isFetching}
        >
        <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
        <Typography variant="h4" gutterBottom>
          Cargando lista de reimpresiones
        </Typography>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isReprinting}
      >
        <CircularProgress color="inherit" sx={{ marginRight: 2 }} />
        <Typography variant="h6" gutterBottom>
          REIMPRIMIENDO ETIQUETA
        </Typography>
      </Backdrop>
      </Dialog>
  );
};

export default ReimpresionesModal;
