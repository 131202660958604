import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { colors, icons } from './AcomodoPage';

const columns = [
    { id: "status", label: "Estatus", minWidth: 1 },
    { id: "lpn", label: "Lpn", minWidth: 1 },
    { id: "oracle", label: "Oracle", minWidth: 1 },
    { id: "item", label: "Descripción", minWidth: 100},
    { id: "cantidad", label: "Cantidad", minWidth: 11},
    { id: "udm", label: "UdM", minWidth: 1 },
    { id: "lote", label: "Lote", minWidth: 1 },
    { id: "creationDate", label: "Fecha", minWidth: 1 },
    { id: "mensaje", label: "Mensaje", minWidth: 100 },
  ];

const AcomodoHistory = ({results}) => {
    const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lpn, setLpn] = useState(0)

  const handleSelectResult = (pro) => {
    setLpn(pro.lpn)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.length > 0 ? results
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow 
                        hover 
                        role="checkbox"
                        selected={row.lpn === lpn}
                        tabIndex={-1} 
                        key={row.pickingId}
                        sx={{cursor: 'pointer'}}
                        onClick={() => handleSelectResult(row)}
                    >
                    {columns.map((column) => {
                      const value = row[column.id];

                      if(column.id === 'status') 
                        return (
                            <TableCell key={column.id} align={column.align} sx={{fontSize: 12}}>
                                <Avatar
                                    sx={{ bgcolor: colors[value], width: 32, height: 32 }}
                                    aria-label="recipe"
                                >
                                {icons[value]}
                                </Avatar>
                            </TableCell>    
                        )
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }): undefined}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={results.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default AcomodoHistory


{/* <div className="historial">
        <div className="rowrep">
          <div className="th">LPN</div>
          <div className="th">Oracle</div>
          <div className="th">Descripcion</div>
          <div className="th">Cantidad</div>
          <div className="th">UDM</div>
          <div className="th">Lote</div>
          <div className="th">Fecha</div>
          <div className="th">Mensaje</div>
        </div>
        {results.map((result) => (
          <div className="rowrep">
            <div className="td">{result.lpn}</div>
            <div className="td">{result.oracle}</div>
            <div className="td">{result.item}</div>
            <div className="td">{result.cantidad}</div>
            <div className="td">{result.udm}</div>
            <div className="td">{result.lote}</div>
            <div className="td">{result.creationDate}</div>
            <div className="td">{result.mensaje}</div>
          </div>
        ))}
      </div> */}