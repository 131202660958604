import { useEffect, useState } from "react";
import getOrgs from '../../../services/org.service';

const useFetchOrgs = () => {
    const [orgs, setOrgs] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    
    useEffect(() => {
        getOrgs()
            .then(resp => setOrgs(resp))
            .finally(() => setIsFetching(false))
    }, [])

  return {orgs, isFetching};
};

export default useFetchOrgs;
